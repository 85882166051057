import SendIcon from "@mui/icons-material/Send";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataDescriptor } from "../context/DataDescriptor";
import { DataQC } from "../context/DataQC";
import { DataUser } from "../context/DataUser";
import styles from "../css/Send.module.css";
import { generateMixAudio } from "../utils/audio/generateMix";
import { updateStageCompleted,projectsListFromDB,updateResourceDeliverables  } from "../utils/project/CRUD";
import { updateManyRegions, updateManyRegionsVoiceOver } from "../utils/regions/updateManyRegions";
import { ModalCheckSend } from "./ModalCheckSend";

export const SendQC = () => {
  const { currentProject, updateRole } = useContext(DataUser);
  const { resultCheck } = useContext(DataQC);
  const { regions } = useContext(DataDescriptor);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [rol, setRol] = useState("")
  const [clientRevision, setClientRevision] = useState({});

  const [openModalCheck, setOpenModalCheck] = useState(false);
  const projectname=currentProject.name.toLowerCase();
  const [TalentIdManage, setTalentIdManage]=useState([])
  console.log("TalentIdManage",TalentIdManage);
  console.log(projectname);
    const [tempLinks, setTempLinks] = useState([]); // Declare tempLinks as a state variable
  console.log("temp",tempLinks);
  
  
  useEffect(() => {
    if (currentProject?.frommanage === "Yes") {
      const fetchProjectsList = async () => {
        try {
          const projects = await projectsListFromDB();
          const textResponse = JSON.stringify(projects);
  
          try {
            const projects = JSON.parse(textResponse);
            
  
            const allResources = [];
            projects.forEach(project => {
              if (project.services) {
                project.services.forEach(service => {
                  if (service.resources) {
                    allResources.push(...service.resources);
                  }
                });
              }
            });
  
           
  
            const filteredResources = allResources.filter(resource => 
              resource.name && resource.name.toLowerCase() === projectname
            );
  
           
  
            const allTalents = filteredResources.flatMap(resource => 
              (resource.talents || []).map(talent => ({
                tpr_id: talent.tpr_id,
                role: talent.role,
                temp_link: resource.temp_link
              }))
            );
            setTalentIdManage(allTalents);
         
  
            const newTempLinks = filteredResources.reduce((acc, resource) => {
              if (resource.temp_link) {
                console.log(`Found temp_link: ${resource.temp_link}`);
                acc.push(resource);
              }
              return acc;
            }, []);
       
           
  
            const projectLinks = filteredResources.reduce((acc, resource) => {
            
  
              if (resource.path_lower) {
               
  
                if (resource.path_lower.includes(`/${projectname}/`)) {
                 
                  acc.push(resource.shared_link);
                }
              }
  
              if (resource.name && resource.name.toLowerCase() === projectname) {
              
                acc.push(resource.temp_link);
              }
  
              return acc;
            }, []);
            setTempLinks(projectLinks);
          
  
          } catch (jsonError) {
            throw new Error("La respuesta no es un JSON válido");
          }
  
        } catch (error) {
          console.error("Error fetching projects:", error);
        }
      };
  
      fetchProjectsList();
    }
  }, [currentProject]);
  
  // Filter tempLinks where path_lower includes the project name
  // Helper function to recursively extract links
  const extractLinks = (folders, projectname) => {
    let links = [];
  
    // Ensure folders is an array
    if (!Array.isArray(folders)) {
      console.error("Expected folders to be an array, but got:", folders);
      return links;
    }
  
    folders.forEach(folder => {
      if (folder.path_lower && folder.path_lower.includes(projectname)) {
        links.push({
          shared_link: folder.shared_link,
          path_lower: folder.path_lower
        });
      }
      if (folder.subfolders && Array.isArray(folder.subfolders) && folder.subfolders.length > 0) {
        links = links.concat(extractLinks(folder.subfolders, projectname));
      }
    });
  
    return links;
  };
  
  // Use the helper function to filter and extract links
  const filteredTempLinks = Object.values(tempLinks).flatMap(folderGroup => {
    // Check if folderGroup is an object and extract its values
    if (typeof folderGroup === 'object' && !Array.isArray(folderGroup)) {
      return Object.values(folderGroup).flatMap(folders => extractLinks(folders, projectname));
    }
    // If folderGroup is already an array, process it directly
    if (Array.isArray(folderGroup)) {
      return extractLinks(folderGroup, projectname);
    }
    console.error("Unexpected folderGroup structure:", folderGroup);
    return [];
  });
  
  console.log("Filtered Temp Links with Path:", filteredTempLinks);
  const extractRequestLinks = (data) => {
    const result = [];
  
    const extractLinksRecursive = (items, parentPath = '') => {
      items.forEach(item => {
        // Revisa si el item tiene requestlinks
        if (item.requestlinks && Array.isArray(item.requestlinks)) {
          item.requestlinks.forEach(requestLinkObj => {
            // Revisa si el requestLink contiene links
            if (requestLinkObj.requestLink && Array.isArray(requestLinkObj.requestLink.link)) {
              requestLinkObj.requestLink.link.forEach(linkItem => {
                // Si hay un file_request_url en el nivel actual
                if (linkItem.file_request_url) {
                  result.push({
                    path: `${parentPath}${linkItem.path_display || ''}`.replace(/\/\//g, '/'),
                    file_request_url: linkItem.file_request_url
                  });
                }
  
                // Si hay subfolders en el link, recursivamente extrae links de esos subfolders
                if (Array.isArray(linkItem.subfolders) && linkItem.subfolders.length > 0) {
                  extractLinksRecursive(linkItem.subfolders, `${parentPath}${linkItem.path_display || ''}/`.replace(/\/\//g, '/'));
                }
              });
            }
          });
        }
  
        // Si el item tiene un file_request_url en el nivel actual
        if (item.file_request_url) {
          result.push({
            path: `${parentPath}${item.path_display || ''}`.replace(/\/\//g, '/'),
            file_request_url: item.file_request_url
          });
        }
  
        // Si el item tiene subfolders, aplica recursividad en esos subfolders
        if (item.subfolders && Array.isArray(item.subfolders)) {
          extractLinksRecursive(item.subfolders, `${parentPath}${item.path_display || ''}/`.replace(/\/\//g, '/'));
        }
      });
    };
  
    extractLinksRecursive(data);
    return result;
  };
  
  // Uso
  const requestLinks = extractRequestLinks(tempLinks);
  console.log("Request Links with Paths:", requestLinks);
  const handleClickOpenModalCheck = () => setOpenModalCheck(true);
  useEffect(() => {
      setClientRevision({
        AD: currentProject?.client[0],
        AN: currentProject?.client[1],
      });
      
  }, [currentProject]);
  const sendProject = async () => {
    const updateQCInDB = async (approved) => {
      const segmentsAD = [];
      const segmentsVO = [];
      regions.forEach(async (region) => {
          const segment={
              review: {
                comment: [approved ? "" : region.data.review.comment[0], approved ? "" : region.data.review.comment[0]],
                approved: approved ? false : region.data.review.approved,
              },
              description: {
                initial: region.data?.description?.initial,
                preview: region.data?.description?.initial,
                reason4change: region?.data?.description?.reason4change,
              },
              audiorecord: {
                initial: region.data?.audiorecord?.initial,
                preview: region.data?.audiorecord?.initial,
              },
            _id: region.data.id
          };
          if(region.data.user) segmentsVO.push(segment);
          else segmentsAD.push(segment);
      });
      if(currentProject.service==="dubbing" || segmentsVO.length>0){
        await updateManyRegionsVoiceOver(currentProject._id, segmentsVO); 
      }
      if(segmentsAD.length>0){
      await updateManyRegions(currentProject._id, segmentsAD);
      }
    }
    const updateVersionInDB = async () => {
      // Update the version in the DB
      const response = await fetch(`${process.env.REACT_APP_URL}/api/projects/${currentProject._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          stage: {
            version: 0,
            state: 'New'
          }
        }),
      });
      await response.json().catch((error) => console.error(error));
    }
    setDisabled(true);
    if (!currentProject.rol?.role.includes("QC") && !currentProject.rol.role.includes("CLIENT")) {

      if (window.location.href.includes("audio-descriptor")) {
        setRol("Quality Control")
        await updateRole("QC");
      }
      else if (window.location.href.includes("narrator")) {
        await updateRole("QC2");
        setRol("Quality Control")
      }

    }else if(currentProject.service==="dubbing"){
      const response = await fetch(`${process.env.REACT_APP_URL}/api/segmentsDubbing/${currentProject._id}`, { method: "GET" });
      const result = await response.json();
      const isThereAnyFalse = result.some(segment => segment.review.approved === false);
      if (isThereAnyFalse) {
        if (currentProject.rol?.role === "QC2") {
          await updateQCInDB(false);
          setRol("Audio Narrator")
          await updateRole("AN_D");
        }
      }else{
        if (currentProject.rol?.role === "QC2") {
          await updateQCInDB(true);
          setRol("Audio Editor to generate mix audio")
          await updateRole("AE");
          generateMixAudio(currentProject._id);
        }

      }
    } else {
      // todo: --------- CLIENT Cases ----------------
      
      const response_client = await fetch(`${process.env.REACT_APP_URL}/api/segments/${currentProject._id}`, { method: "GET" });
      const result_client = await response_client.json();

      let isThereAnyClientComments = false;

      for (const commentObj of result_client) {
        const commentClient = commentObj.commentClient;

        if (commentClient !== null && commentClient !== '' && commentClient !== undefined) {
          isThereAnyClientComments = true;
          break;
        }
      }

      // console.log("isThereAnyClientComments: ", isThereAnyClientComments)
      // console.log("result_client: ", result_client)

      // ? Client funcs
      const updateClientInDB = async () => {
        const segmentsAD = [];
        const segmentsVO = [];
        regions.forEach(async (region) => {
          
          const segment={
            review: {
              comment: ["", ""],
              approved: false,
            },
            description: {
              initial: region.data?.description?.initial,
              preview: region.data?.description?.initial,
              reason4change: region.data?.description?.reason4change,
            },
            audiorecord: {
              initial: region.data?.audiorecord?.initial,
              preview: region.data?.audiorecord?.initial,
            },
            commentClient: "",
            _id: region.data.id
        }
        if(region.data?.user) segmentsVO.push(segment);
        else segmentsAD.push(segment);
      })
      if(currentProject.service==="dubbing" || segmentsVO.length>0){
        await updateManyRegionsVoiceOver(currentProject._id, segmentsVO); 
      }
      if(segmentsAD.length>0){
      await updateManyRegions(currentProject._id, segmentsAD);
      }
      }

      const disapproveSegmentsWithNoComments = async (result_client) => {
        // Update the QC comments array in the DB
        result_client.forEach(async (region) => {
          try {
              await putReviewSegment(
                {
                  review: {
                    comment: [region.review.comment[0], region.review.comment[1]],
                    approved: false,
                  }
                },
                region._id
              );
            // console.log("Region: ", region)
          } catch (error) {
            console.error(error)
          }
        });
      }

      const approveClientSegmentsWithNoComments = async (result_client) => {
        // Update the QC comments array in the DB
        result_client.forEach(async (region) => {
          // console.log("region: ", region)
          try {
            // todo: Approve all segments with no comments
            if (region.commentClient === null || region.commentClient === '') {
              await putReviewSegment(
                {
                  review: {
                    comment: ["", ""],
                    approved: true,
                  }
                },
                region._id
              );
            } else {
              await putReviewSegment(
                {
                  review: {
                    comment: [region.review.comment[0], region.review.comment[1]],
                    approved: false,
                  }
                },
                region._id
              );
            }
            // console.log("Region: ", region)
          } catch (error) {
            console.error(error)
          }
        });
      }



      if (currentProject.rol.role === "CLIENT") {
        console.log("CLIENT")
        // setRol("Quality Control")
        // await updateFirstTimeClientInDB();
        // await updateRole("QC");
        if (isThereAnyClientComments) {
          console.log("isThereAnyClientComments")
          setRol("Quality Control")
          await updateRole("QC");
          await approveClientSegmentsWithNoComments(result_client);
        } else {
          console.log("No isThereAnyClientComments")
          setRol("Audio Narrator")
          await updateRole("AN");
          await disapproveSegmentsWithNoComments(result_client);
        }
      }

      if (currentProject.rol.role === "CLIENT2") {
        // setRol("Quality Control")
        // await updateFirstTimeClientInDB();
        // await updateRole("QC2");
        if (isThereAnyClientComments) {
          setRol("Quality Control")
          await updateRole("QC2");
          await approveClientSegmentsWithNoComments(result_client);
        } else {
          setRol("Audio Editor")
          await updateRole("AE");
          await approveClientSegmentsWithNoComments(result_client);
          await updateStageCompleted(currentProject._id);
        }
      }


      // todo: --------- QC Cases ----------------
    

      const response = await fetch(`${process.env.REACT_APP_URL}/api/segments/${currentProject._id}`, { method: "GET" });
      const result = await response.json();
      const isThereAnyFalse = result.some(segment => segment.review.approved === false);

      if (isThereAnyFalse) {
        if (currentProject.rol?.role === "QC") {
          await updateQCInDB(false);
          setRol("Audio Descriptor")
          await updateRole("AD");

        } else if (currentProject.rol?.role === "QC2") {
          await updateQCInDB(false);
          setRol("Audio Narrator")
          await updateRole("AN");
        }
      } else {
        if (currentProject.rol?.role === "QC") {
          await updateQCInDB(true);
          await updateVersionInDB();
          if (clientRevision.AD && !isThereAnyClientComments) {
            setRol("Client")
            await updateClientInDB();
            await updateRole("CLIENT");
          } else {
            //aqui se pasa a AN

          
            setRol("Audio Narrator")
            await updateClientInDB();
            await updateRole("AN");
            if (currentProject?.frommanage === "Yes") {
              console.log("TalentIdManage:", TalentIdManage); // Verifica el contenido
              const descriptorTalent = TalentIdManage.find(talent => talent.role === 'Descriptor');
              const descriptorLink = filteredTempLinks.find(link => link.path_lower.includes("descriptor"));
              console.log("Descriptor Talent:", descriptorTalent); //
              const updatedDeliverables = descriptorTalent ? {
                tpr_id: descriptorTalent.tpr_id,
                deliverables: {
                  '0': {
                    approvedDate: new Date().toISOString(), // Add the approved date
                    link: descriptorLink ? descriptorLink.shared_link : null, // Include the link if found
                    name: "Descriptor",
                    state: 'approved'
                  }
                }
              } : {};
              
              // Verifica si el objeto se ha creado correctamente
              console.log("Datos enviados a updateResourceDeliverables:", updatedDeliverables);
                  try {
                    // Llamada a la función para actualizar los deliverables
                    await updateResourceDeliverables({ updatedDeliverables });
                    console.log("Deliverables actualizados:", updatedDeliverables);
                  } catch (error) {
                    console.error("Error al actualizar deliverables:", error);
                  }
                }

          }
        } else if (currentProject.rol?.role === "QC2") {
          await updateQCInDB(true);
          if (clientRevision.AN && !isThereAnyClientComments) {
            setRol("Client")
            await updateClientInDB();
            await updateRole("CLIENT2");
          } else {
            setRol("Audio Editor")
            await updateRole("AE");
            await updateClientInDB();  
                      
            await updateStageCompleted(currentProject._id);
            if (currentProject?.frommanage === "Yes") {
              console.log("TalentIdManage:", TalentIdManage); // Verifica el contenido
              const narratorTalent = TalentIdManage.find(talent => talent.role === 'Narrator');
              const narratorLink = filteredTempLinks.find(link => link.path_lower.includes("Narrator"));
              console.log("Narrator Talent:", narratorTalent); //
              const updatedDeliverables = narratorTalent ? {
                tpr_id: narratorTalent.tpr_id,
                deliverables: {
                  '0': {
                    approvedDate: new Date().toISOString(), // Add the approved date
                    link: narratorLink ? narratorLink.shared_link : null, // Include the link if found
                    name: "Narrator",
                    state: 'approved'
                  }
                }
              } : {};
              
              // Verifica si el objeto se ha creado correctamente
              console.log("Datos enviados a updateResourceDeliverables:", updatedDeliverables);
                  try {
                    // Llamada a la función para actualizar los deliverables
                    await updateResourceDeliverables({ updatedDeliverables });
                    console.log("Deliverables actualizados:", updatedDeliverables);
                  } catch (error) {
                    console.error("Error al actualizar deliverables:", error);
                  }
                }
            // generateMixAudio(currentProject._id);
          }
        }
      }

    }
    setOpenToast(true);
    setTimeout(() => {
      setDisabled(false);
      setOpenToast(false);
      navigate("/dashboard");
    }, 3000);
  };

  // Call to DB to save QC comments
  const putReviewSegment = async (data, id) => {
    const response = await fetch(`${process.env.REACT_APP_URL}/api/segments/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const dataResponse = await response.json();
    return dataResponse;
  };


  return (
    <>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={"success"} variant="filled">
          for review {rol}
        </Alert>
      </Snackbar>
      {disabled ?
        <button
          className={styles.button__send__disabled}
          disabled={disabled}
        >
          <CircularProgress aria-label='Loading' />
        </button>
        : (
          <button
            className={!resultCheck || currentProject.rol.role === "CLIENT" || currentProject.rol.role === "CLIENT2" ? styles.button__send : styles.button__send__disabled}
            onClick={() => handleClickOpenModalCheck()}
            disabled={resultCheck}
          >
            <SendIcon sx={{ fontSize: 40 }} />
            <span>SEND</span>
          </button>
        )}
      <ModalCheckSend
        open={openModalCheck}
        setOpen={setOpenModalCheck}
        sendProject={sendProject}
        disabled={disabled}
      />

    </>
  );
};


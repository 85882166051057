
export const updateNameProject = async (project, name) => {
  const resp = await fetch(`${process.env.REACT_APP_URL}/api/projects/${project._id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
    }),
  });
  const data = await resp.json();

  return data;
};

export const getProject = async (id) => {
  if (id) {
    const resp = await fetch(`${process.env.REACT_APP_URL}/api/projects/${id}`);
    const data = await resp.json();
    return data;
  } 
};

export const updateStage = (stage, idProject) => {
  if (stage.state !== "In progress") {
    fetch(`${process.env.REACT_APP_URL}/api/projects/stage/${idProject}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ stage: "In progress" }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        return data;
      })
      .catch((err) => console.error(err));
  }
};
export const updateStageCompleted = ( idProject) => {
    fetch(`${process.env.REACT_APP_URL}/api/projects/stage/${idProject}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ stage: "completed" }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        return data;
      })
      .catch((err) => console.error(err));
};

export const updateProject = async (project) => {
  const data = await fetch(`${process.env.REACT_APP_URL}/api/projects/${project._id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(project),
  });
  const res = await data.json().catch((err) => console.error(err));

  return res;
}

export const createCategory = async (name) => {
  const resp = await fetch(`${process.env.REACT_APP_URL}/api/categories`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name }),
  });
  const data = await resp.json();
  return data;
};

export const getAllCategories = async () => {
  const resp = await fetch(`${process.env.REACT_APP_URL}/api/categories`);
  const data = await resp.json();
  return data;
}
export   const projectsListFromDB = async () => {
  const res = await fetch(`${process.env.REACT_APP_URL_MANAGE}/projectsBasicInfo`)
  const data = await res.json();
  return data;
}
export const updateResourceDeliverables = async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL_MANAGE}/updateTPRDeliverablesA4V`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),  // Aquí envías el objeto directamente
  });

  if (!response.ok) {
      throw new Error('Error al actualizar el proyecto');
  }
  return response.json();
};
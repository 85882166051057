import { createContext, useState } from "react";
import { parseJwt } from "../utils/parse-jwt";

export const DataUser = createContext();

export const DataUserProvider = ({ children }) => {
  const [currentProject, setCurrentProject] = useState({});
  const [deviceOutput, setDeviceOutput] = useState("Same as system");
  const [deviceInput, setDeviceInput] = useState("Same as system");
  const [jwt, setJwt] = useState(localStorage.getItem("token") || "");
  const [projects, setProjects] = useState([]);
  const [projectsOriginal, setProjectsOriginal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState(null);
  const [projectsFilter, setProjectsFilter] = useState(null)
  const [filter, setFilter] = useState('');
  const [user, setUser] = useState(null);
  const [stream, setStream] = useState(null);
  const [maxPeak, setMaxPeak] = useState(0);
  const [minPeak, setMinPeak] = useState(0);
  const [avgPeak, setAvgPeak] = useState(0);

  const [narrationType, setNarrationType] = useState("Standard");

  const [rolFilter, setRolFilter] = useState(null);
  const [stageFilter, setStageFilter] = useState(null);
  const [page, setPage] = useState(1);

  const handlerSetUser = (user) => setUser(user);

  const getUser = async () => {

    const token = localStorage.getItem("token");
    if (!token || user) {
      return null;
    }
    const { uid } = parseJwt(token);
    const path = `${process.env.REACT_APP_URL}/api/users/user/${uid}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    if (!user) {
      const resp = await fetch(path, options);
      const data = await resp.json();

      return data.userDB;
    }

  };
  // peticion actualizar rol
  const updateRole = async (rol) => {
    const resp = await fetch(`${process.env.REACT_APP_URL}/api/projects/rol/${currentProject._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        rol,
      }),
    });
    await resp.json();
  };
  const getProyects = async (page = null, newRequest = true, rol = null, stage = null) => {
    if (newRequest) {
      setLoading(true);
      setProjects([]);
      setProjectsOriginal([]);
    }
    const token = localStorage.getItem("token");
    const { uid } = parseJwt(token);
    let path = `${process.env.REACT_APP_URL}/api/projects`;

    if (user?.roles.includes("PM") || user?.roles.includes("QC")) {
      path += `/pm/${uid}`;
    } else {
      path += `/user/${uid}`;
    }

    if (rol !== null) {
      path += `?role=${rol}`;
    }
    if (page !== null) {
      if (rol !== null) {
        path += `&page=${page}`;
      } else {
        path += `?page=${page}`;
      }
    }
    if (stage !== null) {
      if (rol !== null || page !== null) {
        path += `&stage=${stage}`;
      } else {
        path += `?stage=${stage}`;
      }
    }


    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },

    };
    await fetch(path, options)
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          if (data.projects.length === 0) {
            setProjects([]);
            setProjectsOriginal([]);
            setLoading(false);
            return;
          }
          if (newRequest) {
            setProjects(data.projects);
            setProjectsOriginal(data.projects);
            setPage(1);
          } else {
            const projectsData = projects.concat(data.projects);
            setProjects(projectsData);
            setProjectsOriginal(projectsData);
          }
          setLoading(false);
          const dataProjectsFilter = {
            totalProjects: data.totalProjects,
            totalAD: data.totalAD,
            totalAN: data.totalAN,
            totalAE: data.totalAE,
            totalARC: data.totalARC,
            totalQC: data.totalQC,
            totalSCRIPTADAPTER: data.totalSCRIPTADAPTER,
            totalINTERPRETER: data.totalINTERPRETER,
            totalCLIENT: data.totalCLIENT,
            totalToReview: data.totalToReview,
            totalInProgress: data.totalInProgress,
            totalNew: data.totalNew,
          }
          setPagination({
            totalPages: data.totalPages,
            totalProjectsPage: data.totalProjectsPage,
            currentPage: data.currentPage,
            totalProjects: data.totalProjects,
          })

          setProjectsFilter(dataProjectsFilter)
          setLoading(false);

        } else {
          setLoading(false);
        }
      });
  };
  return (
    <DataUser.Provider
      value={{
        currentProject,
        setCurrentProject,
        jwt,
        setJwt,
        user,
        handlerSetUser,
        getUser,
        deviceOutput,
        setDeviceOutput,
        deviceInput,
        setDeviceInput,
        updateRole,
        getProyects,
        projects,
        setProjects,
        stream,
        setStream,
        maxPeak,
        setMaxPeak,
        minPeak,
        setMinPeak,
        avgPeak,
        setAvgPeak,
        projectsOriginal, setProjectsOriginal,
        filter, setFilter,
        loading, setLoading,
        narrationType, setNarrationType,
        projectsFilter,
        pagination,
        rolFilter,
        setRolFilter, page, setPage,
        stageFilter, setStageFilter
      }}
    >
      {children}
    </DataUser.Provider>
  );
};

import BookmarkIcon from "@mui/icons-material/Bookmark";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { DataDashboard } from "../../context/DataDashboard";
import { DataPM } from "../../context/DataPM";
import { DataUser } from "../../context/DataUser";
import styles from "../../css/OngoingProjects.module.css";

export const OngoingProjects = ({ roles, user, setProjects,projects, projectsOriginal,projectsFilter }) => {
  const [stagging, setStagging] = useState([]);
  let toogleFilter = false
  const [isFilter, setIsFilter] = useState(false)
  const [nameFilter, setNameFilter] = useState("");
  const [count, setCount] = useState(0);

  const {
    isCreate,
    setIsCreate,
    countDescrptors,
    countNarrators,
    countEditors,
    countDubbing,
    countQC,countArchived
  } = useContext(DataPM);
  const { countToReview, countInProgress, countNew, searchCount, } = useContext(DataDashboard);
  const {getProyects,setPage, setRolFilter, setStageFilter,setFilter} = useContext(DataUser);
  

  let statesProjects = [
    {
      name: "Describer",
      count: projectsFilter?.totalAD,
    },
    {
      name: "Narrator",
      count: projectsFilter?.totalAN,
    },
    {
      name: "Audio Editor",
      count: projectsFilter?.totalAE,
    },
    {
      name: "Client",
      count: projectsFilter?.totalCLIENT,
    },
    {
      name: "Quality Control",
      count: projectsFilter?.totalQC,
    },
    {
      name: "Completed",
      count: projectsFilter?.totalARC,
    }
  ];

  const stagesProjectsUsers = [
    {
      name: "To review",
      count: projectsFilter?.totalToReview,
    },
    {
      name: "In progress",
      count: projectsFilter?.totalInProgress,
    },
    {
      name: "New",
      count: projectsFilter?.totalNew,
    },
  ];
  const stagesProjectsUsersQC = [
    {
      name: "To review",
      count: projectsFilter?.totalToReview,
    },
    {
      name: "In progress",
      count: projectsFilter?.totalInProgress,
    },
    {
      name: "New",
      count: projectsFilter?.totalNew,
    },
    {
      name: "Active",
      count: projectsFilter?.totalProjects,
    }
  ];

  

  useEffect(() => {
    if (roles.includes("PM")) setStagging(statesProjects);
    else if (roles.includes("QC")) setStagging(stagesProjectsUsersQC)
    else setStagging(stagesProjectsUsers);
  }, [
    roles,
    countDescrptors,
    countNarrators,
    countEditors,
    countDubbing,
    countQC,
    countToReview,
    countInProgress,
    countNew,
    projectsOriginal
  ]);

  useEffect(() => {
    if (user?.roles.includes("QC")) {
      let filter = projectsOriginal.filter((project) => (project.rol.user_id === user?.uid));
      searchCount(filter);
      setProjects(filter);
    }
    else searchCount(projectsOriginal);
  }, [projectsOriginal, user]);

  const filterProjects = (name) => {

    const filterBtn = document.getElementById("filter-btn");
    filterBtn.setAttribute("aria-live", "polite");
    filterBtn.setAttribute("aria-label", "Projects filtered");
    setTimeout(() => {
      filterBtn.setAttribute("aria-live", "off");
      filterBtn.setAttribute("aria-label", name + " projects, filter");
    }, 1000);

    if (name === nameFilter) {
      toogleFilter = false
      setFilter("All Projects in Progress")
      setCount(count + 1);
      if ((count + 1) % 2 === 0) toogleFilter = true
    } else {
      setCount(0);
      toogleFilter = true
    }

    if (name === "Active") {
      toogleFilter = !toogleFilter;
      if (toogleFilter) {
        setStageFilter(null);
        setPage(1)
        getProyects(1, true,"QC")
      } else {
        setStageFilter("Active");
        setPage(1)
        getProyects(1, true)

      }
    } else {
      toogleFilter = !toogleFilter
      if (!toogleFilter) {
        let filter = []
        if (user?.roles.includes("PM")) {
          let rol = ''
          switch (name) {
            case "Describer":
              rol = "AD"
              setFilter("Describer")
              break;
            case "Narrator":
              rol = "AN"
              setFilter("Narrator")
              break;
            case "Audio Editor":
              rol = "AE"
              setFilter("Audio Editor")
              break;
            case "Client":
              rol = "CLIENT"
              setFilter("Client")
              break;
            case "Quality Control":
              rol = "QC"
              setFilter("Quality Control")
              break;
            case "Completed":
              rol = "ARC"
              setFilter("Completed")
              break;
            default:
              break;
          }
          setPage(1)
          setRolFilter(rol)
       
          if(user?.roles.includes("QC")) getProyects(1, true, "QC")
          else getProyects(1, true, rol)
        } else {
          setPage(1)
          setStageFilter(name);
        
          if(user?.roles.includes("QC")) {
            getProyects(1, true, "QC", name)
          }
          else getProyects(1, true, null, name)
        }
      } else {
        if (user?.roles.includes("PM")){
          setRolFilter("");
          setPage(1)
          getProyects(1, true)
          }
        else {
          setStageFilter(null);
          setRolFilter(null);
          setPage(1)
          if(user?.roles.includes("QC")) getProyects(1, true, "QC")
          else getProyects(1, true, null)
          
        }
      }
      
    }
    setNameFilter(name);
    setIsFilter(toogleFilter);
  };

  return (
    <aside className={styles.container_Vertical}>
      <header className={styles.header__ongoingProjects}>
        <h2 tabIndex={0} aria-label="Ongoing Projects">
          <BookmarkIcon /> Ongoing Projects
        </h2>
      </header>
      <main className={styles.container__ongoingProjects}>
        <div>
          {stagging.map(({ name, count }, index) => {
            let backgroundColor, countBackgroundColor;

            switch (name) {
              case "To review":
                backgroundColor = "#4a0d28";
                countBackgroundColor = "#8D1B4E";
                break;
              case "In progress":
                backgroundColor = "#632755";
                countBackgroundColor = "#A93F90";
                break;
              case "New":
                backgroundColor = "#145D5F";
                countBackgroundColor = "#12B4B8";
                break;
              case "Describer":
                backgroundColor = "#777082";
                countBackgroundColor = "#A9A6B2";
                break;
              case "Narrator":
                backgroundColor = "#4a0d28";
                countBackgroundColor = "#8D1B4E";
                break;
              case "Audio Editor":
                backgroundColor = "#632755";
                countBackgroundColor = "#A93F90";
                break;
              case "Client":
                backgroundColor = "#3D1C47";
                countBackgroundColor = "#6A2C7A";
                break;
              case "Quality Control":
                backgroundColor = "#145D5F";
                countBackgroundColor = "#12B4B8";
                break;
              case "Active":
                backgroundColor = "#4a0d28";
                countBackgroundColor = "#8D1B4E";
                break;
              case "Completed":
                backgroundColor = "#1c4731";
                countBackgroundColor = "#2E7D5C";
                break;
                
              default:
                backgroundColor = "transparent";
                countBackgroundColor = "transparent";
                break;
            }

            return (
              <div
                tabIndex={0}
                aria-label={`${count} ${name} projects, filter`}
                // aria-live="assertive"
                role="button"
                id="filter-btn"
                key={index}
                className={(!isFilter && name === nameFilter) ? styles.card__containerActive : styles.card__container}
                onClick={() => {
                  filterProjects(name);
                }}
              >
                <div
                  className={styles.container_name}
                  style={{ backgroundColor }}
                >
                  <h4 >{name}</h4>
                </div>
                <div
                  className={styles.container_count}
                  style={{ backgroundColor: countBackgroundColor }}
                >
                  <span>{count}</span>
                </div>
              </div>
            );
          })}
        </div>
        {user?.roles.includes("PM") && (
          <button
            className={styles.btn_create}
            onClick={() => setIsCreate(!isCreate)}
          >
            {isCreate ? "Cancel" : "New Project"}
          </button>
        )}
      </main>
    </aside>

  );
};
